import { useQuery } from "@apollo/react-hooks";
import { Col, Row, Space } from "antd";
import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import { GET_ORDER } from "../../../graphql/order";
import OrderSummary from "../../checkout/OrderSummary";
import OrderCard from "./OrderCard";

const OrderDetail = ({ id }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_ORDER, { variables: { id } });

  const order = data?.getOrder;

  if (!order) return null;

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Row justify="center">
        <Link to="/app/orders">{`< ${t("detail.back")}`}</Link>
      </Row>

      <Row justify="center" gutter={[40, 40]}>
        <OrderSummary order={order} />
        <Col md={20}>
          <OrderCard loading={loading} order={order} />
        </Col>
      </Row>
    </Space>
  );
};
export default OrderDetail;
